<template>
  <div style="margin: 20px">
    <v-table v-loading="loading" :fetch="fetchData" name="requests" :columns="columns" :row-class-name="rowClassName"/>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getRequestsList } from '@/api/api'
import { getIogvDict } from '@/api/catalogs'
export default {
  components: {
    VTable
  },
  data () {
    return {
      iogvs: [],
      loading: false
    }
  },
  computed: {
    columns () {
      const classifications = [
        {
          id: 1,
          title: 'Общий'
        },
        {
          id: 2,
          title: 'Индивидуальный'
        },
        {
          id: 4,
          title: 'Контрольный показатель'
        }
      ]
      const statuses = [
        { id: 1, title: 'Новый' },
        { id: 2, title: 'В работе' },
        { id: 3, title: 'Отклонен' },
        { id: 4, title: 'Доступ открыт' },
        { id: 5, title: 'Отменен заявителем' }
      ]
      const iogvs = this.iogvs
      return [
        {
          field: 'status_id',
          filter (value) {
            return statuses?.find(x => x.id === value)?.title || ''
          },
          items: statuses
        },
        {
          field: 'ebosp_short_name',
          items: iogvs,
          multiple: true
        },
        {
          field: 'from_ebosp',
          items: iogvs,
          multiple: true
        },
        {
          field: 'pki_classification',
          width: '155px',
          items: classifications
        },
        {
          field: 'pki_index',
          width: '100px'
        }
      ]
    }
  },
  mounted () {
    this.loading = true
    getIogvDict().then((response) => {
      this.iogvs = response.data.data
      this.loading = false
    })
  },
  methods: {
    fetchData: (...args) => getRequestsList(...args),
    rowClassName (row) {
      if (row.row.status_id === 1) {
        return 'status-new'
      }
    }
  }
}
</script>

<style scoped>

</style>
