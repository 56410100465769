import request from '@/utils/request'

export function getViceGovernorsDict (params) {
  return request({
    url: '/backend/dictionary/vice-governor',
    method: 'post',
    data: params
  })
}

export function getIogvDict (params) {
  return request({
    url: '/backend/dictionary/iogv',
    method: 'post',
    data: params
  })
}

export function getUsers (params) {
  return request({
    url: '/backend/dictionary/user',
    method: 'post',
    data: params
  })
}

export function getPeriods (params) {
  return request({
    url: '/backend/dictionary/period',
    method: 'post',
    data: params
  })
}

export function getSymbols (params) {
  return request({
    url: '/backend/dictionary/symbol',
    method: 'post',
    data: params
  })
}

export function getPki (params) {
  return request({
    url: '/backend/dictionary/pki',
    method: 'post',
    data: params
  })
}

export function getMainDirectionActivity (params) {
  return request({
    url: '/backend/dictionary/main-direction-activity',
    method: 'post',
    data: params
  })
}

export function getPeriodView (params) {
  return request({
    url: '/backend/dictionary/period-view',
    method: 'post',
    data: params
  })
}

export function getUnitOfMeasurement (params) {
  return request({
    url: '/backend/dictionary/unit-of-measurement',
    method: 'post',
    data: params
  })
}
